<template>
  <div>
    <div class="d-flex align-center mb-4">
      <v-avatar
        color="#dedede"
        class="member-card__avatar"
        darken
        size="40"
        elevation="1"
      >
        <img v-if="avatar" :src="avatar" :alt="fullName" />
        <v-icon v-else color="white" size="24">mdi-account</v-icon>
      </v-avatar>
      <div class="d-flex align-center ml-2">
        <div>
          <p class="title">
            {{ fullName || "#" + admin.id }}
          </p>
          <div class="body-2">ID наставника: {{ admin.id }}</div>
        </div>
      </div>
    </div>

    <option-row title="Администратор:">{{
      admin.is_owner ? "да" : "нет"
    }}</option-row>

    <option-row v-if="createDate" title="Создан:">{{ createDate }}</option-row>
    <option-row v-if="updateDate" title="Обновлен:">{{
      updateDate
    }}</option-row>
    <option-row v-if="admin?.user" title="Talent ID:"
      ><a
        href="#"
        class="link"
        @click.stop.prevent="openDialog(admin.user.id)"
        >{{ admin.user.id }}</a
      ></option-row
    >
  </div>
</template>
<script>
import { pathJoin } from "@/api/utils";
import { DATE_DATABASE_FORMAT, DATE_DISPLAY_FORMAT } from "@/constants";

import dayjs from "@/plugins/dayjs";
export default {
  name: "AdminCard",
  props: {
    admin: {
      type: Object,
      required: true,
    },
  },

  computed: {
    avatar() {
      const {
        admin: { user },
      } = this;
      if (!user?.avatar) return "";
      if (user.avatar.startsWith("https")) {
        return user.avatar;
      }
      return pathJoin(process.env.VUE_APP_AVATAR_S3, user.avatar);
    },
    fullName() {
      const { admin } = this;
      if (!admin.user) return "";
      return [
        admin.user.last_name,
        admin.user.first_name,
        admin.user.middle_name,
      ]
        .filter(Boolean)
        .join(" ");
    },
    createDate() {
      if (!this.admin?.created_at) return;
      return dayjs(this.admin.created_at, DATE_DATABASE_FORMAT).format(
        DATE_DISPLAY_FORMAT
      );
    },
    updateDate() {
      if (!this.admin?.updated_at) return;
      return dayjs(this.admin.updated_at, DATE_DATABASE_FORMAT).format(
        DATE_DISPLAY_FORMAT
      );
    },
  },
  methods: {
    openDialog(userId) {
      if (userId) {
        this.$emit("openDialog", userId);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  color: inherit !important;
  transition: color 0.3s;
  max-width: 600px;
  margin-bottom: 0;
}
</style>
