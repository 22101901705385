<template>
  <div>
    <admins-section :admins="admins" @setAdminsPage="getAdmins" />
  </div>
</template>
<script>
import AdminsSection from "@/components/organizations/AdminsSection.vue";
import { talentClient } from "@/api";
import { initialListingModel } from "@/utils";

export default {
  name: "OrganizationAdminsPage",
  components: { AdminsSection },
  data() {
    return {
      admins: initialListingModel(10),
    };
  },
  computed: {
    organization() {
      return this.$store.state.organizations.organization;
    },
  },
  watch: {
    organization: {
      handler(val) {
        if (val?.id) {
          this.getAdmins(this.admins.page);
        }
      },
    },
  },
  activated() {
    if (this.organization) {
      this.getAdmins(this.admins.page);
    }
  },
  methods: {
    async getAdmins(page = 1) {
      const adminId = this.organization?.admin_users?.[0]?.user_id;
      if (this.admins.pending || !adminId) return;
      this.admins.error = "";
      this.admins.pending = true;
      this.admins.page = page;
      try {
        const { data } = await talentClient({
          method: "GET",
          url: `users/${adminId}/organizations/${this.$route.params.orgId}/admins/`,
          params: {
            limit: this.admins.limit,
            offset: (this.admins.page - 1) * this.admins.limit,
          },
        });
        this.admins.list = data.results;
        this.admins.total = data.count;
        this.admins.pagesCount = Math.ceil(data.count / this.admins.limit);
      } catch (error) {
        console.log("error", error);
        this.admins.error = error.message;
      }
      this.admins.pending = false;
    },
  },
};
</script>
